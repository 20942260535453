import React from "react";
import "./Contato.css";

function Contato() {
  return (
    <div class="contato-wrapper">
      <div className="contato-container">
        <div className="map-div">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3969.148843593126!2d-35.22842963108781!3d-5.834632144552459!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7b2ff23d96b0b4b%3A0xc1b0e983685adf53!2sGABRIEL%20BULH%C3%95ES%20-%20Advocacia%20Criminal%20%26%20Investiga%C3%A7%C3%A3o%20Defensiva!5e0!3m2!1spt-BR!2sbr!4v1681494583173!5m2!1spt-BR!2sbr"
            width="100%"
            height="100%"
            style={{ border: 0, padding: 0, margin: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
        <div className="contato-content">
          <h2>CONTATO</h2>
          <h3>Fale conosco!</h3>
          <p>
            <i class="fa fa-whatsapp"></i>(84) 99100-9447
          </p>
          <p>
            <i class="uil uil-phone"></i>(84) 3033 1881
          </p>
          <p>
            <i class="uil uil-envelope"></i>contato@gabrielbulhoes.com
          </p>
          <p>
            <i class="uil uil-location-point"></i>OTC – Office Tower Center,
            Sala 303 Rua Promotor Manoel Alves Pessoa Neto, 45 Candelária,
            Natal, CEP: 59065-555
          </p>
          <div className="socials">
            <a
              href="https://www.linkedin.com/company/gb-advocacia-criminal/"
              target="_blank"
              rel="noreferrer"
              aria-label="site principal do escritório"
            >
              <i className="uil uil-linkedin-alt"></i>
            </a>

            <a
              href="https://www.instagram.com/gbadvocaciacriminal/"
              target="_blank"
              aria-label="site principal do escritório"
              rel="noreferrer"
            >
              <i className="uil uil-instagram"></i>
            </a>

            <a
              href="https://wa.me/5584991009447"
              aria-label="Whatsapp Icon"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-whatsapp"></i>
            </a>

            <a
              href="mailto:contato@gabrielbulhoes.com.br"
              target="_blank"
              rel="noreferrer"
              aria-label="site principal do escritório"
            >
              <i class="uil uil-envelope"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contato;
