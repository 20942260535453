import React from "react";
import "./Atuacao.css";
import Icon1 from "../../assets/imgs/icon1.png";
import Icon2 from "../../assets/imgs/icon2.png";
import Icon3 from "../../assets/imgs/icone-direito-penal.png";
import Icon4 from "../../assets/imgs/investig-def-icon.png";

function Atuacao() {
  return (
    <div className="home-atuacao-container">
      <h1 className="section-title">
        Veja as áreas de atuação e especialidades
      </h1>
      <p className="section-subtitle">
        O modo de trabalho adotado é determinado pela busca incessante da
        excelência dentro do Direito, regada ao contínuo e intenso estudo das
        ciências criminais e empresariais.
      </p>

      <div className="atuacao-flex">
        <div className="atuacao-flex-row">
          <div>
            <div className="img-container"><img src={Icon1} alt="balança" /></div>
            <h4>Direito Penal Econômico</h4>
          </div>
          <div>
            <div className="img-container"><img src={Icon3} alt="livro de leis" /></div>
            <h4>Defesa Penal Clássica</h4>
          </div>
        </div>
        <div className="atuacao-flex-row">
          <div>
            <div className="img-container"><img src={Icon2} alt="aperto de mãos" /></div>
            <h4>Compliance Criminal & Investigações Internas Corporativas</h4>
          </div>
          <div>
            <div className="img-container"><img src={Icon4} alt="livros" /></div>
            <h4>Investigação Defensiva</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Atuacao;
