import React from "react";
import "./SobreGabriel.css";
import Gabriel from "../../assets/imgs/IMG_1817 (2).jpg";
function SobreGabriel() {
  return (
    <div class="sobre-gabriel-wrapper">
      <div className="sobre-gabriel-container">
        <div>
          <img src={Gabriel} alt="Gabriel Bulhões"></img>
        </div>
        <div class="sobre-gabriel-content">
          <div>
            <h1>Gabriel Bulhões</h1>
            <h2>Advogado Criminalista e Sócio-gestor</h2>
          </div>
          <p>- Advogado Criminalista;</p>
          <p>- Head/CEO da ETHOSBrasil.org;</p>
          <p>- Doutorando em Ciências Criminais na PUC/RS, sob orientação do Professor Jacinto Nelson de Miranda Coutinho;</p>
          <p>- Mestre em Ciências Criminais na PUC/RS, sob orientação do Professor Nereu Giacomolli e co-orientação do Professor Jacinto Nelson de Miranda Coutinho;</p>
          <p>- Vice-presidente da Comissão Especial de Estudos para Regulamentação da Investigação Defensiva do CFOAB;</p>
          <p>- Presidente da Comissão Nacional de Investigação Defensiva e Inovações Tecnológicas da ABRACRIM;</p>
          <p>- Diretor Jurídico nacional da Associação Nacional de Peritos em Computação Forense (APECOF).</p>


          <a class="main-btn" href="https://wa.me/5584991009447" aria-label="Whatsapp Icon" target="_blank" rel="noreferrer">Fale Conosco!</a>
        </div>
      </div>
    </div>
  );
}

export default SobreGabriel;
