import React, { useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import "./App.css";
import "./assets/styles/Reset.css";
import Hero from "./components/hero/Hero";
import PontosFortes from "./components/PontosFortes/PontosFortes";
import FAQ from "./components/FAQ/FAQ";
import Depoimentos from "./components/Depoimentos/Depoimentos";
import CTAMaior from "./components/CTAMaior/CTAMaior";
import Contato from "./components/contato/Contato";
import SobreEscritorio from "./components/sobreEscritorio/SobreEscritorio";
import CTA from "./components/CTA/CTA";
import Especialidades from "./components/servicos/Servicos";
import SobreGabriel from "./components/sobreGabriel/SobreGabriel";
import Atuacao from './components/especialidades/Atuacao';

function App() {
  const main = process.env.REACT_APP_MAIN;

  const inicio = useRef();
  const atuacao = useRef();
  const sobre = useRef();
  const contato = useRef();

  const scrollToComponent = (refName) => {
    const refs = {
      inicio,
      atuacao,
      sobre,
      contato,
    };

    const selectedRef = refs[refName];

    if (selectedRef && selectedRef.current) {
      var yOffset;
      if (refName === "InicioRef") {
        yOffset = -180;
      } else {
        yOffset = -60;
      }

      const yCoordinate =
        selectedRef.current.getBoundingClientRect().top +
        window.scrollY +
        yOffset;
      window.scrollTo({ top: yCoordinate, behavior: "smooth" });
    }
  };

  return (
    <Router>
      <div className="App">
        <Header scrollToComponent={scrollToComponent} />

        <div class="body-wrapper">
          <div class="body-container">
            <div ref={inicio}>
              <Hero />
            </div>
            <Atuacao />

            <div ref={atuacao}>
              <Especialidades />
            </div>

            <div ref={sobre}>
              <SobreEscritorio />
            </div>

            <SobreGabriel />

            <PontosFortes />

            <CTAMaior />




            <Depoimentos />
            <div ref={contato}>
              <Contato />
            </div>

            <FAQ />

            <CTA />
          </div>
        </div>

        <div className="whats-btn">
          <a
            href="https://wa.me/5584991009447"
            target="_blank"
            rel="noreferrer"
          >
            <i class="uil uil-whatsapp whats"></i>
          </a>
        </div>
          <Footer scrollToComponent={scrollToComponent} />
      </div>
    </Router>
  );
}

export default App;
