import React from "react";
import "./SobreEscritorio.css";
import Palestra from "../../assets/imgs/gb-palestra.jpg";
import Escritorio from "../../assets/imgs/escritorio-2.jpeg";
import EscritorioMesa from "../../assets/imgs/escritorio-mesa.jpg";

function SobreEscritorio() {
  return (
    <div class="sobre-escritorio-wrapper">
      <div className="sobre-escritorio-container">
        <div className="sobre-escritorio-content">
          <div>

            <h2>GABRIEL BULHÕES</h2>
            <h3> Advocacia Criminal & Investigação Defensiva</h3>
          </div>
          <p>
            Nosso escritório oferece o profissionalismo dos grandes escritórios, com a atenção personalizada da advocacia artesanal. Valorizamos a confiança, ética e confidencialidade, buscando sempre a excelência teórica e prática.
          </p>
          <p>
            Com atendimento direto pelo Dr. Gabriel Bulhões e equipe, garantimos aconselhamento jurídico detalhado e acompanhamento processual ágil. Construímos uma relação de confiança com nossos clientes, proporcionando segurança e clareza em todas as estratégias adotadas.
          </p>
          <p>
            Nossa atuação é marcada pela busca contínua de soluções inovadoras no Direito, alinhada ao constante estudo das ciências criminais e empresariais, e ao monitoramento dos principais tribunais e instâncias sancionatórias.
          </p>

          <a class="main-btn" href="https://wa.me/5584991009447" aria-label="Whatsapp Icon" target="_blank" rel="noreferrer">Fale Conosco!</a>

        </div>

        <div class="sobre-escritorio-imgs">
          {/* no mobile vai ser igual murassawa porém, com apenas duas imagens juntas */}
          <div>
            <div class="image-container palestra"></div>
            <div class="image-container escritorio-mesa"></div>
          </div>

          <div className="desktop-div">
            <div class="sobre-escritorio-img-btn-container">
              <a class="main-btn" href="https://wa.me/5584991009447" aria-label="Whatsapp Icon" target="_blank" rel="noreferrer">Fale Conosco!</a>
            </div>
            <div class="image-container escritorio"></div>
          </div>

        </div>

      </div>
    </div>
  );
}

export default SobreEscritorio;
