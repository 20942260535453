import React from "react";
import "./Hero.css";
import Disclaimer from "../utils/Disclaimer";

function Hero() {
  const main = process.env.REACT_APP_MAIN;

  return (
    <div className="hero-wrapper">
      <div className="hero-overlay"></div>
      <div className="hero-container">
        <div className="hero-content">
{/* 
          <h1>Advocacia Criminal e Investigação Defensiva</h1>
          <p>
            Primamos por técnica, profissionalismo, confiança, ética e sigilo nos serviços prestados
          </p> */}

          <div>
            <h1>GABRIEL BULHÕES</h1>
            <h2>Advocacia Criminal & Investigação Defensiva</h2>
          </div>
          <p>Oferecemos aos nossos clientes os resultados e o profissionalismo dos grandes escritórios, mas sem abrir mão do cuidado personalizado, típico de uma advocacia artesanal. </p>

          <a
            href="https://wa.me/5584991009447"
            target="_blank"
            rel="noreferrer"
            className='main-btn'>Entre em contato</a>
        </div>
      </div>
    </div>
  );
}

export default Hero;
 