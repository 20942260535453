import React from 'react';
import './Depoimentos.css';

function Depoimentos() {
  return (
    <div>
      <div className='teste'>
        <h2>Avaliações</h2>
      </div>
      <div className=''>
        <div class="elfsight-app-51588043-c517-43b5-bf48-5b11b1669032" data-elfsight-app-lazy></div>
      </div>
      <div className='teste2'>

      </div>
    </div>
  );
}

export default Depoimentos;
