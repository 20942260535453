import React from "react";
import "./Servicos.css";
import Logo from '../../assets/imgs/gb.svg';
import especialidadesData from './servicos.json';

function Especialidades() {
  return (
    <div className="servicos-wrapper">
      <div className="servicos-container">
        <div className="header">
          <img src={Logo} alt="logo" />
          <h2>Conheça os nossos serviços</h2>
        </div>
        <div className="servicos-grid">
          {especialidadesData.map((item, index) => (
            <div key={index} className="servicos-card">
              <div className="card-header">
                <i className="uil uil-balance-scale"></i>
                <h3>{item.titulo}</h3>
              </div>
              <ul>
                {item.items.map((service, i) => (
                  <li key={i}><i class="uil uil-angle-right"></i>{service}</li>
                ))}
              </ul>
              <a
                href="https://wa.me/5584991009447"
                target="_blank"
                rel="noreferrer"
              >
                <i className="uil uil-angle-double-right"></i> Fale conosco
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Especialidades;
