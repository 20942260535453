import React from 'react';
import './PontosFortes.css';

function PontosFortes() {
  return (
    <div className="pontos-fortes-wrapper">
      <div className='pontos-fortes-container'>
        <div>
          <i className="uil uil-shield-check"></i>
          <h4>Investigação Defensiva</h4>
          <p>Nossa expertise em investigação defensiva nos permite construir estratégias sólidas para proteger os direitos dos nossos clientes em todas as fases do processo.</p>
        </div>
        <div>
          <i className="uil uil-balance-scale"></i>
          <h4>Especialização Jurídica</h4>
          <p>Nossa equipe conta com profissionais especializados e experientes, garantindo uma representação legal de excelência em diversas áreas do Direito, incluindo crimes ambientais, administrativos e penais.</p>
        </div>
        <div>
          <i class="uil uil-puzzle-piece"></i>
          <h4>Defesa Estratégica</h4>
          <p>Com uma abordagem estratégica e proativa, buscamos sempre proteger os interesses e direitos dos nossos clientes, oferecendo uma representação jurídica assertiva e focada em resultados.</p>
        </div>
      </div>
    </div>
  );
}

export default PontosFortes;
