import React from 'react';
import CustomForm from './Form';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

const url = 'https://bcej.us13.list-manage.com/subscribe/post?u=1a05048b99e056a06bf750c3a&amp;id=271c640c60&amp;f_id=00a0fbe6f0';

const Demo = () => {
    return (
        <div>
           
            <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                    <CustomForm status={status} message={message} onValidated={subscribe} />
                )}
            />
        </div>
    );
};

export default Demo; 
